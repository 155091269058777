button, input {
  padding: 0;
  border: 0;
  background: none;
}

input, textarea, button, a {
  &:focus, &:active {
    outline: none;
  }
}

.l-main{
  position: relative;
  @include container();
  min-height: calc(100% - 130px);
  display: table;

  &.order, .contacts {
    display: table;
    background-color: $white;
  }

  .slider {
    @include row();
    position: relative;
    width: 100%;
    max-width: 100%;
    //    height: 700px;

    .bxslider {
      @include unstyled();
    }

    .bx-pager {
      position: absolute;
      bottom: 12.9%; //90px;
      right: 8.44%; //108px;

      .bx-pager-item {
        display: inline-block;

        &:not(:first-child) {
          margin-left: 10px;
        }
      }

      a {
        display: inline-block;
        width: 9px;
        height: 9px;
        background-color: #7a7a7a;
        border-radius: 50%;
        text-indent: -9999px;

        &:not(:first-child) {
          margin-left: 10px;
        }

        &.pager-active, &.active {
          background-color: $white;
        }
      }
    }

    .img-slider-wrap {
      float: left;
      font-size: 0;
      width: 100%;
    }

    img {
      width: 100%; //960px;
      height: 100%;
      //      height: 700px;
    }

    li {
      position: relative;
      float: left;
      display: inline-block;
      width: 100%;
      height: 640px;//100%;

      &:not(:first-of-type) {
        display: none;
      }
    }

    .bx-viewport li:not(:first-of-type) {
      display: inline-block;
    }

    .info{
      position: absolute;
      bottom: calc( 12.9% - 10px );//77px;
      left: 65px;
      width: 75%;//960px;
      box-sizing: border-box;
      text-shadow: 1px 1px 2px #4a3474,0 0 0 #000,1px 1px 2px #4a3474;
//      background-color: #fff;
      color: $white;
      text-align: left;

      .title{
        font: {
          family: $font-family-second;
          size: rem(150);
          weight: 400;
        }
        line-height: 1.0;
        text-shadow: 1px 1px 5.52px rgba(0, 0, 0, 0.8);
        margin-bottom: 0;
      }

      .desc{
        font: {
          family: $font-family-second;
          size: rem(48);
          weight: 400;
        }
        line-height: 1.5;
        text-shadow: 1px 1px 5.52px rgba(0, 0, 0, 0.8);
      }

      .desc-main{
        line-height: 1.2;
      }
    }
  }

  .l-info{
    @include row();

    .link-to-services{
      width: 50%;
      height: 320px;
      display: block;
      float: left;
      text-decoration: none;
      background-color: $link-to-bg-color;
      .title{
        width: 50%;
        display: block;
        float: left;
        padding: 67px 48px 0;
        line-height: 33px;
        color: $link-to-color;
        font: {
          family: $link-to-title-ff;
          size: $link-to-title-fz;
        }
      }

      img{
        width: 50%;
        display: block;
        float: left;
        height: 100%;
      }
    }

    .subscription{
      width: 50%;
      height: 320px;
      float: left;
      background-color: $subscription-bg-color;

      .title{
        height: 50%;
        color: $subscription-title-color;
        text-align: center;
        font: {
          family: $subscription-title-ff;
          size: $subscription-title-fz;
        }
        padding: 70px 150px 0;
        line-height: 1.4;
      }
      form{
        input{
          display: block;
          margin: auto;
          background-color: $subscription-input-bg-color;
          width: 256px;
          height: 40px;
          text-align: center;
          padding: 0 10px;
          outline: none;
          border: none;
          font: {
            family: $subscription-input-ff;
            size: $subscription-input-fz;
          }
          @include placeholder(){
            color: $subscription-input-placeholder-color;
            font: {
              family: $subscription-input-ff;
              size: $subscription-input-fz;
            }
          }
        }
        button{
          display: block;
          margin: auto;
          width: 256px;
          height: 40px;
          font: {
            family: $subscription-btn-ff;
            size: $subscription-btn-fz;
          }
          background-color: $subscription-btn-bg-color;
          color: $subscription-btn-color;
          outline: none;
          border: none;
        }
      }
    }

    .contacts{
      width: 50%;
      height: 320px;
      float: left;
      padding-top: 80px;
      text-align: center;
      background-color: $contacts-bg-color;
      color: $contacts-color;

      .title{
        font: {
          family: $contacts-title-ff;
          size: $contacts-title-fz;
        }
        margin-bottom: 23px;
      }

      ul{
        @include unstyled();
        font-size: $contacts-data-fz;

        li{
          &:not(:first-child){
            margin-top: 27px;
          }
        }
      }

      .address{

      }

      .tel{
        padding-top: 10px;
        display: block;
      }
    }

    .link-to-catalog{
      width: 50%;
      height: 320px;
      display: block;
      float: left;
      text-decoration: none;
      background-color: $link-to-bg-color;
      .title{
        width: 50%;
        display: block;
        float: left;
        padding: 67px 48px 0;
        line-height: 33px;
        color: $link-to-color;
        font: {
          family: $link-to-title-ff;
          size: $link-to-title-fz;
        }
      }

      img{
        width: 50%;
        display: block;
        float: left;
        height: 100%;
      }
    }
  }

  .new {
    @include row();
    display: table;
    width: 100%;
    height: 427px;

    .new-desc {
      display: table-cell;//inline-block;
      vertical-align: top;
      width: 50%;//calc(50% + 2px); //642px;;
      height: 100%;
      border-width: 0 1px 1px 0;
      border-style: solid;
      border-color: #efefef;
      box-sizing: border-box;
      padding: 68px 36px 20px 63px;
      background-color: $white;
      color: #555655;
      text-align: left;
      text-decoration: none;

      &:first-of-type {
        border-width: 1px 1px 1px 0;
      }

      .date {
        font-family: $font-family-first;
        font-size: rem(16);
        font-weight: 400;
        line-height: 36px;

        b {
          display: block;
          font-family: $font-family-second-light;
          font-size: rem(72);
          font-weight: 300;
          line-height: 48px;
        }
      }

      h1 {
        margin: 8px 0 9px 0;
        max-width: 95%;
        font-family: $font-family-second;
        font-size: rem(30);
        font-weight: 500;
        line-height: 48px;
        text-transform: uppercase;
      }

      p {
        margin-top: 0;
        font-family: $font-family-first;
        font-size: rem(18);
        font-weight: 400;
        line-height: 24px;
      }

      &:hover {
        background-color: #828b86;
        border-color: #828b86;
        color: #ffffff;
      }
    }

    .new-desc__vendor {
      display: table-cell;//inline-block;
      vertical-align: top;
      width: 50%;//calc(50% + 2px); //642px;;
      height: 100%;
      border-width: 0 1px 1px 0;
      border-style: solid;
      box-sizing: border-box;
      padding: 68px 36px 20px 63px;
      background-color: #828b86;
      border-color: #828b86;
      color: #ffffff;
      text-align: left;
      text-decoration: none;

      &:first-of-type {
        border-width: 1px 1px 1px 0;
      }

      .date {
        font-family: $font-family-first;
        font-size: rem(16);
        font-weight: 400;
        line-height: 36px;

        b {
          display: block;
          font-family: $font-family-second-light;
          font-size: rem(72);
          font-weight: 300;
          line-height: 48px;
        }
      }

      h1 {
        margin: 8px 0 9px 0;
        max-width: 95%;
        font-family: $font-family-second;
        font-size: rem(30);
        font-weight: 500;
        line-height: 48px;
        text-transform: uppercase;
      }

      p {
        margin-top: 0;
        font-family: $font-family-first;
        font-size: rem(18);
        font-weight: 400;
        line-height: 24px;
      }

      &:hover {
        background-color: #828b86;
        border-color: #828b86;
        color: #ffffff;
      }
    }



    img {
      display: table-cell;//inline-block;
      vertical-align: top;
      width: 100%;//calc(50% - 2px); //638px;
      max-height: 427px;
      height: 100%;
    }
  }
/*
  .contacts {
    @include row();
    height: 359px;

    .contact {
      position: relative;
      display: inline-block;
      vertical-align: top;
      width: 50%;
      height: 100%;
      padding: 38px 400px 20px 44px;
      box-sizing: border-box;
      background-color: #ffffff;
      color: #000000;
      text-align: left;

      &:hover {
        background-color: #f37e4a;
        color: #FFF;

        b {
          color: #FFF;
        }
      }

      .city {
        margin-bottom: 23px;
        font-family: $font-family-second;
        font-size: rem(18);
        font-weight: 500;
        line-height: 30px;
      }

      p, b {
        font-family: $font-family-first;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        text-align: left;
      }
      p {
        margin-top: 0;
        margin-bottom: 23px;
      }

      b {
        display: block;
        color:  #eb7034;
        line-height: 22px;
      }

      .send {
        text-decoration: none;
        position: absolute;
        top: 45px;
        right: 46px;
        width: 228px;
        height: 40px;
        font-family: $font-family-first;
        background-color: #000000;
        color: #ffffff;
        font-size: rem(18);
        font-weight: 400;
        line-height: 40px;
        text-align: center;
        @include hoveropacity();
      }
    }
  }
*/
  #map {
    @include row();
    height: 848px;
  }

  .search-wrap {
    @include row();
    position: relative;
    z-index: 8;
//    height: 109px;
    padding: 39px 65px 40px 65px;
    background: #828b86;
    @include background(linear-gradient(0deg, #757e79 0%, #8a928d 100%));

    &.catalog {
      padding: 50px 65px 28px 65px;

//      .filters-container {
//        margin-top: 23px;
//      }
    }

    .search-container {
      position: relative;
//      width: 1150px;
//      margin: 0 auto;
      background-color: #969f9a;

      &:before {
        position: absolute;
        top: calc(50% - 28px / 2);
        left: 21px;
        content: '';
        font-family: $fontello;
        color: #fff;
        font-size: rem(28);
        font-weight: 400;
      }
    }

    input {
      width: 100%;
      height: 54px;
      padding: 0 17px 0 55px;
      border: none;
      box-sizing: border-box;
      background-color: transparent;
      color: #fff;
      text-align: left;
      font: {
        family: $font-family-first;
        size: rem(22);
        weight: 400;
      }
    }

    .full-string {
      position: relative;
      margin: 40px 0 0 0;//40px -8px 0 -8px;
//      padding: 4px 0;
      padding-right: 37px;
      font-family: $font-family-first;
      font-size: 0;
      color: #ffffff;
      font-weight: 400;
      line-height: 23px; //30px;
      text-align: left;

      a {
        display: inline-block;
        max-width: 33%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
//          margin-right: 30px;
        font-size: rem(16);
//        margin-left: 5px;
        margin-right: 5px;
        margin-bottom: 1px;
        padding: 3px 8px 3px 8px;
        border-radius: 15px;
        @include hoveropacity();

        &:after {
          content: '';
          font-family: "fontello";
          color: #555655;
          font-size: 0;
          display: inline-block;
          vertical-align: middle;
          transition: font-size .3s;
          margin-left: 10px;
        }

        &.active {
          background-color: #FFF;
          color: #555655;

          &:after {
            font-size: 1rem;
          }
        }

//        &:first-of-type {
//          margin-left: 0;
//        }

        &:last-of-type {
          margin-right: 0;
        }
      }

      .reset {
        position: absolute;
        top: 2px;//2px;
        right: 0;
        font-family: $fontello;
        font-size: rem(24);
        line-height: 1;
        font-weight: 400;
        @include hoveropacity();
      }
    }

    .filters-container {
//      width: 1150px;
      margin-top: 16px;
      font-family: $font-family-first;
      color: #ffffff;
      font-size: rem(16);
      font-weight: 400;
      line-height: 30px;
      text-align: left;

      tr {
        &:last-of-type .filter-param {
          border-bottom: none;
        }
      }

      td {
        padding: 14px 0;
      }

      .filter-name {
        white-space: nowrap;
        vertical-align: top;
        max-width: 250px;
        padding-right: 30px;
        color:  #555655;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      a {
        @include hoveropacity();
      }

      .filter-param {
        width: 100%;
        padding-top: 11px;
        border-bottom: 1px solid rgba(255, 255, 255, 0.2);
        vertical-align: top;
        div {
          max-width: 100%;
          height: 32px;//29px;
          overflow-y: hidden;
        }
        a {
          display: inline-block;
          max-width: 40%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
//          margin-right: 30px;
          margin-left: 1px;
          margin-bottom: 7px;
          padding: 0 8px 0 8px;
          border-radius: 15px;

          &.active {
            background-color: #FFF;
            color: #555655;
          }

          &:first-of-type {
            margin-left: 0;
          }
        }
      }

      .expand {
        vertical-align: top;
        display: none;
        padding-left: 20px;
        font-family: $fontello;
        color: #ffffff;
        font-size: rem(24);
        font-weight: 400;
        text-align: left;
      }

      .can-expand {
        .expand {
          display: block;
        }

        &.expanded {

          .filter-param {
            padding-bottom: 0;//8px;
            div {
              height: auto !important;
              overflow: visible;
            }
          }
          .expand {
            transform: scale(1,-1);
          }
        }
      }
    }

    & ~ .catalog-container {
      border-top: none;
    }
  }

  .search-wrap-scroll {
    position: fixed;
    z-index: 6;
    top: -63px;
    left: calc( ( 100% - 1280px ) / 2 );
    width: 1280px;
//    height: 63px;
    padding: 22px 60px 16px 60px;
    background-color: #828b86;
    @include transition(top 0.5s ease-in-out, left 0.1s linear, background-color 0.25s linear);
    cursor: pointer;

    &.swim {
      top: 0;
    }

    &:hover {
      background-color: #757e79;
    }

    .magnifier, .parameters {
      display: inline-block;
      font-family: $fontello;
      color: #fff;
      font-size: 24px;
      font-weight: 400;
      line-height: 24px;
      text-decoration: none;
    }
    .parameters {
      float: right;
    }
  }

  @media screen and (max-width: 1294px ) {
    .search-wrap-scroll {
      width: auto;
      padding: 18px 40px 12px 40px;
      left: 0;
      right: 0;
    }
  }

  @media screen and (max-width: 999px ) {
    .search-wrap-scroll {
      width: auto;
      padding: 10px 30px 8px 30px;
      left: 0;
      right: 0;
    }
  }

  .vendors-container, .catalog-container {
    position: relative;
    border-top: 1px solid #efefef;
    border-left: 1px solid #efefef;
    background-color: $white;

    a.vendor {
      @include hoveropacity();
    }

    .vendor {
      position: relative;
      display: inline-block;
      vertical-align: top;
      width: 33.33%;//320px;
      height: 426px;
      padding-bottom: 107px;
      border-width: 0 1px 1px 0;
      border-style: solid;
      border-color: #efefef;
      box-sizing: border-box;
      background-color: #fff;
      text-align: center;

      img {
        display: inline-block;
        vertical-align: middle;
        max-width: 60%;
        max-height: 270px;
      }

      .origin {
        position: absolute;
        top: 305px;
        left: 12%;
        width: 76%;
        font-family: $font-family-first;
        color: #555655;
        font-size: rem(16);
        font-weight: 400;
        line-height: 24px;

        b {
          display: block;
          font-family: $font-family-second;
          color: #555655;
          font-size: rem(24);
          font-weight: 500;
          line-height: 1;
        }
      }
    }

    a.wine {
      text-decoration: none;
      transition: box-shadow 0.5s;
      box-shadow: 0 0 10px rgba(0,0,0,0);

      &:hover {
        box-shadow: 0 0 10px rgba(0,0,0,0.5);
        z-index: 2;
      }
    }

    .wine {
      position: relative;
      display: inline-block;
      vertical-align: top;
      width: 33.33%;//320px;
      height: 426px;
      padding: 45px 64px 0 64px;
      border-width: 0 1px 1px 0;
      border-style: solid;
      border-color: #efefef;
      box-sizing: border-box;
      background-color: #ffffff;
      text-align: left;

      img {
        float: right;
        vertical-align: middle;
        max-width: 34%;
        max-height: 345px;
      }

      .name {
        max-width: 195px;
        margin-bottom: 9px;
        font-family: $font-family-second;
        color: #555655;
        font-size: rem(24);
        font-weight: 500;
        line-height: 30px;
        text-transform: uppercase;
      }

      .origin {
        max-width: 195px;
        font-family: $font-family-first;
        color: #555655;
        font-size: rem(16);
        font-weight: 400;
        line-height: 24px;
      }

      .bottom {
        position: absolute;
        bottom: 15px;
        left: 64px;
        right: 64px;

        .cost {
          margin-top: 22px;
          font-family: $font-family-second-light;
          color: #555655;
          font-size: rem(24);
          font-weight: 500;
          line-height: 30px;
          word-spacing: -0.1em;
          text-transform: uppercase;

          b {
            font-family: $font-rouble;
            font-size: rem(22);
            font-weight: 400;
          }
        }

        .buy {
          margin-top: 2px;
          color: #000000;
          .tocart {
            width: 54px;
            height: 54px;
            border-radius: 50%;
            background-color: #f5f5f5;
            font-family: $fontello;
            font-size: 30px;
            font-weight: 400;
            line-height: 54px;
            text-align: center;
            border-width: 0;
            &:hover {
              color: #828b86;
            }
          }
          .count {
//            width: 83px;
            margin-bottom: 1px;
            height: 54px;
            color: #555655;
            font-family: $font-family-first;
            font-size: rem(20);
            font-weight: 400;
            line-height: 54px;
//            border-radius: 27px;

            > div, .minus, .plus {
              background: #f5f5f5;
              display: inline-block;
              vertical-align: middle;
              text-align: center;
            }
            .minus, .plus {
              display: inline-block;
              width: 42px;
//              padding: 0 12px;
              text-align: center;
              font-size: rem(24);
              font-weight: 400;
//              line-height: 30px;
              color:  #555655;

              &:hover {
                color: #828b86;
              }
            }
            .pieces {
              margin: 0 2px;
              width: 59px;
            }
            .minus {
//              padding-left: 12px;
              border-top-left-radius: 27px;
              border-bottom-left-radius: 27px;
            }
            .plus {
//              padding-right: 12px;
              border-top-right-radius: 27px;
              border-bottom-right-radius: 27px;
            }
          }
        }
      }
    }

    &.reduced {
      width: 66.66%;
      height: 100%;
      margin-left: 33.33%;

      .wine {
        width: 50%;
      }
    }

    .load-container {
      @include allspace();
      top: -1px;
      z-index: 6;
      min-height: 427px;
      padding: ( 426px - 196px ) / 2 0;
      background-color: rgba(40, 40, 40, 0.4);
      font-size: 0;
      text-align: center;

      .arc {
        stroke: $reload-color;
      }

      .arrow {
        fill: $reload-color;
      }
    }
  }

  .catalog-pager {
    padding: 30px 30px;
    font-family: $font-family-second;
    font-size: 0;
    font-weight: 500;
    line-height: 1;
    color: #555655;
    text-align: center;

    a {
      margin-left: 8px;

      &:first-of-type {
        margin-left: 0;
      }
    }

    &__prev, &__next {
      font-size: rem(16);
      display: inline-block;
      vertical-align: middle;
      font-family: $fontello;
      @include hoveropacity();
    }

    &__link {
//      height: 30px;
      display: inline-block;
      vertical-align: middle;
      padding: 6px 7px 5px;
      font-family: $font-family-second;
      font-size: rem(16);
      color: inherit;
      text-decoration: none;
      border: 1px solid transparent;
      @include TRANSITION();

      &.active {
        background-color: #555655;
        color: $white;
      }

      &:hover {
        border-color: #555655;
      }
    }
  }

  .order-info {
    position: absolute;
    top: 0;
    left: 0;
    width: 33.33%;
    bottom: 0;
    min-height: 700px;
    padding: 71px 65px;
    background: #828b86;
    color: $white;
//    width: 320px;
//    height: 1280px;

    &.full {
      width: 100%;
      padding: 49px 58% 49px 46px;

      .order-text {
        width: 85%;
      }
    }

    .order-text {
      font-family: $font-family-second;
      font-size: rem(30);
      font-weight: 500;
      line-height: 42px;
      text-transform: uppercase;
    }

    .order-total-cost {
      font-family: $font-family-second-light;
      font-size: rem(72);
      font-weight: 300;
      line-height: 80px;
      word-spacing: -0.1em;

      b {
        font-family: $font-rouble;
        color: #ffffff;
        font-size: rem(72);
        font-weight: 400;
      }
    }

    .go {
      display: inline-block;
      width: 100%;
      margin-top: 34px;
      padding: 12px 40px;
      background: #3f4d45;
      font-family: $font-family-first;
      font-size: rem(20);
      font-weight: 400;
      line-height: 30px;
      text-align: center;
      @include hoveropacity(0.7, #FFF);
    }

    .order-details {
      margin-top: 27px;
      font-family: $font-family-first;
      font-size: rem(18);
      font-weight: 400;
      line-height: 30px;
    }

    .contact-details {
      position: absolute;
      top: 0;
      right: 0;
      width: 50%;
      padding: 50px 42px 74px 42px;

      label {
        display: block;
        margin-bottom: 28px;
        span {
          display: block;
          margin-bottom: 4px;
          font-family: $font-family-first;
          font-size: rem(16);
          font-weight: 400;
          line-height: 30px;
          color: #3f4d45;
          cursor: pointer;
          @include hoveropacity();
        }
        input, textarea {
          width: 100%;
          height: 54px;
          padding: 0 24px;
          background-color: #969f9a;
          font-family: $font-family-first;
          color: #FFF;
          font-size: rem(20);
          font-weight: 400;
          line-height: 1.4;//40px;
          text-align: left;
        }

        textarea {
          min-height: 76px;
          max-height: 175px;
          padding: 10px 24px;
          resize: vertical;
        }

        [placeholder] {
          color: #FFF;
        }
        ::-webkit-input-placeholder {
          color: #FFF;
        }
        ::-moz-placeholder {
          color: #FFF;
        }
        :-ms-input-placeholder {
          color: #FFF;
        }
        :-moz-placeholder {
          color: #FFF;
        }
      }

      .go {
        float: right;
        width: auto;
        margin-top: 31px;
        padding: 12px 111px;
      }
    }
  }

  .features-wrap {
    @include row();
    display: table;
    background-color: #FFF;

    .image, .features {
//      display: inline-block;
      display: table-cell;
      vertical-align: top;
      width: 50%;
      min-height: 1277px;
    }

    .features {
      overflow-y: auto;
    }

    .features {
      padding: 73px 65px 70px 65px;
      box-sizing: border-box;
      background-color: #828b86;
      color: #ffffff;
      text-align: center;

      .name {
        margin-bottom: 1px;
        font-family: $font-family-second;
        font-size: rem(30);
        font-weight: 500;
        line-height: 42px;
        text-transform: uppercase;
        text-align: left;
      }

      .cost {
        margin-bottom: 58px;
        font-family: $font-family-second-light;
        font-size: rem(72);
        font-weight: 300;
        line-height: 80px;
        text-align: left;
        word-spacing: -0.1em;

        b {
          font-family: $font-rouble;
          font-size: rem(72);
          font-weight: 400;
        }
      }

      .attribute {
        display: inline-block;
        vertical-align: top;
        width: 44%;//226px;
        padding: 12px 0;
        border-top: 1px solid #9ba29e;
        font-family: $font-family-first;
        font-size: rem(16);
        font-weight: 400;
        line-height: 24px;
        text-align: left;

        &:nth-child(2n) {
          width: 254px;
          margin-left: 25px;
        }
      }

      .tocart {
        display: inline-block;
        width: 100%;
        height: 54px;
        margin-top: 3px;
        margin-bottom: 83px;
        background-color: #3f4d45;
        font-family: $font-family-first;
        font-size: rem(18);
        font-weight: 400;
        line-height: 54px;
        text-align: center;
        @include hoveropacity(0.7, #FFF);
      }

      .count {
        position: relative;
        display: inline-block;
        height: 54px;
        width: 147px;
        margin-top: 3px;
        margin-bottom: 83px;
        color: #555655;
        font-family: $font-family-first;
        font-size: rem(20);
        font-weight: 400;
        line-height: 54px;
        text-align: center;
        border-radius: 27px;

        > div {
          display: inline-block;
          vertical-align: middle;
        }
        .minus, .plus {
//          position: absolute;
          display: inline-block;
          vertical-align: top;
          background: #f5f5f5;
//          padding: 0 24px;
          width: 42px;
          text-align: center;
          cursor: pointer;
          &:hover {
            opacity: 0.6;
          }
        }
        .pieces {
          display: inline-block;
          vertical-align: top;
//          padding: 0 4px;
          width: 59px;
          background-color: #f5f5f5;
          height: 100%;
          line-height: 54px;
        }
        .minus {
//          top: 0;
//          left: 0;
          float: left;
          border-top-left-radius: 27px;
          border-bottom-left-radius: 27px;
        }
        .plus {
//          top: 0;
//          right: 0;
          float: right;
          border-top-right-radius: 27px;
          border-bottom-right-radius: 27px;
        }
      }
    }

    p {
      margin-top: 0;
      margin-bottom: 7px;
      font-family: $font-family-first;
      color: #FFF;
      font-size: rem(18);
      font-weight: 400;
      line-height: 1.67;//30
      text-align: left;

      &.bold {
        margin-bottom: 46px;
        color: #ffffff;
        font-size: rem(24);
        font-weight: 400;
        line-height: 1.67;//40px;
      }
    }
    .image {
      padding: 40px 0;
      text-align: center;
      background-color: $white;

      img {
        max-width: 55%;//300px;
        max-height: 100%;//1200px;
      }
    }
  }

  .article-image {
    position: relative;
    font-size: 0;

    img {
      width: 100%;
//      margin-bottom: -4px;
    }

    .back {
      position: absolute;
      top: 29px;
      left: 22px;
      font-family: $font-family-first;
      color: #ffffff;
      font-size: rem(16);
      font-weight: 400;
      line-height: 1.67;//30px;
      text-align: left;
      text-shadow: 1px 1px 5.52px rgba(0, 0, 0, 0.8);
      @include hoveropacity(0.7, #FFF);

      b {
        vertical-align: middle;
        margin-right: 12px;
        font-family: $fontello;
        font-size: rem(24);
        font-weight: 400;
        text-align: left;
      }
    }

    .change {
      position: absolute;
      top: 29px;
      left: 132px;
      font-family: $font-family-first;
      color: #ffffff;
      font-size: rem(16);
      font-weight: 400;
      line-height: 1.67;//30px;
      text-align: left;
      @include hoveropacity(0.7, #FFF);

      b {
        vertical-align: middle;
        margin-right: 12px;
        font-family: $fontello;
        font-size: rem(24);
        font-weight: 400;
        text-align: left;
      }
    }

    .info{
      position: absolute;
      bottom: 77px;
      left: 65px;
      width: 75%;//960px;
      box-sizing: border-box;
      text-shadow: 1px 1px 2px #4a3474,0 0 0 #000,1px 1px 2px #4a3474;
//      background-color: #fff;
      color: $white;
      text-align: left;

      .title{
        font: {
          family: $font-family-second;
          size: rem(150);
          weight: 400;
        }
        text-shadow: 1px 1px 5.52px rgba(0, 0, 0, 0.8); 
        line-height: 1;
//        padding-bottom: 20px;
        margin-bottom: 0px;
      }

      .desc{
        font: {
          family: $font-family-second;
          size: rem(48);
          weight: 400;
        }
        text-shadow: 1px 1px 5.52px rgba(0, 0, 0, 0.8); 
        line-height: 1.5;
      }
    }
  }

  .article-tocatalog {
    background-color: #828b86;
    padding: 77px 0;
    text-align: center;
    color: #ffffff;
    width: 50%;


    .desc {
      width: 74%;
      margin: 0 auto;
      font-family: $font-family-second;
      font-size: rem(48);
      font-weight: 500;
      line-height: 60px;
      text-align: center;
    }

    .go {
      display: inline-block;
      margin-top: 39px;
      padding: 12px 128px;
      background-color: #3f4d45;
      font-family: $font-family-first;
      font-size: rem(18);
      font-weight: 400;
      line-height: 30px;
      text-align: center;
      @include hoveropacity(0.7, #FFF);
    }
  }

//  .article-row {
//    @include row();

  .article-image, .article-entry, .article-entry-big, .article-link, .article-tocatalog, .article-logo, .article-quote, .article-slider, .article-small-image, .catalog-container {
    position: relative;

    .control {
      position: absolute;
      z-index: 5;
      top: 30px;
      right: 30px;

      a {
        display: inline-block;
        margin-right: 20px;
        margin-bottom: 20px;
        padding: 10px 15px;
        @include hoveropacity();
        background-color: rgba(0, 0, 0, 0.60);
        border-radius: 15px;
        font-size: 0;

        &:last-of-type {
          margin-right: 0;
        }
      }

      img {
        width: auto;
        max-width: 25px !important;
        max-height: 15px !important;
        margin-bottom: 0;
        @include transform(none);
      }
    }
  }

  .article-entry, .article-entry-big, .article-link {
    display: inline-block;
    vertical-align: top;
    position: relative;
    background-color: $white;
    color: #000000;
    width: 50%;
    height: $row-height;
    padding: 51px 50px 52px 64px;//33px 48px 55px 48px;
    overflow-y: auto;
  }

  .article-entry, .article-entry-big {
    p {
      margin: 26px 0 0 0;
      font-family: $font-family-first;
      color: #555655;
      font-size: rem(18);
      font-weight: 400;
      line-height: 1.67;//30px;
      letter-spacing: -0.01em;

      &.big {
        margin-top: 23px;
        font-size: rem(24);
        line-height: 1.67;//40px;
        letter-spacing: 0;

        & + p {
          margin-top: 50px;
        }
      }

      a {
        text-decoration: underline;
        color: inherit;

        &:visited {
          color: #92278f;
        }
      }
    }
    ol {
      margin-top: 0;
      padding-left: 0;
      font-family: $font-family-first;
      color: #555655;
      font-size: rem(18);
      font-weight: 400;
      line-height: 1.667;
      counter-reset: main-counter;

      li {
        position: relative;
//        list-style-position: outside;
        list-style: none;

        &:before {
          content: counter(main-counter) '. ';
          counter-increment: main-counter;
          position: absolute;
          top: 5px;
          right: calc( 100% + 12px );
          color: rgba(85, 86, 85, 0.7);
          font-size: 77.8%;//rem(14);
        }
      }
    }
  }

  .article-entry-big {
    height: 2 * $row-height;
  }

  .article-entry {
    height: 100%;

    .go {
      display: block;
      width: 100%;
      padding: 12px 10px;
      font: {
        family: $subscription-btn-ff;
        size: $subscription-btn-fz;
      }
      line-height: 1.5;
      background-color: $subscription-btn-bg-color;
      color: $subscription-btn-color;
      outline: none;
      border: none;
      float: left;
      width: 40%;
      text-align: center;
      @include hoveropacity(0.7, #FFF);
    }
  }

  .article-link {
    padding-top: 82px;
    padding-left: 65px;
    padding-right: 50px;
    padding-bottom: 120px;
    background-color: #b1a083;
    color: #ffffff;

    .desc-wrap {
      display: inline-block;
      vertical-align: middle;

      .desc {
        font-family: $font-family-second;
        font-size: rem(48);
        font-weight: 500;
        line-height: 1.25;
      }

      .go {
        position: absolute;
        bottom: 77px;
        left: 65px;
        right: 65px;
        margin-top: 43px;
        font-family: $font-family-first;
        font-size: rem(16);
        font-weight: 400;
        line-height: 30px;
        @include hoveropacity(0.7, #FFF);
        color: #6c5a3a;
      }
    }
  }

  .article-quote, .article-logo, .article-small-image {
    display: inline-block;
    vertical-align: top;
    position: relative;
    width: 50%;
    height: $row-height;
    background-color: $white;
    color: #000000;
  }

  .article-quote {
    .quote {
      position: relative;
      display: inline-block;
      vertical-align: middle;
      padding: 60px 63px;
      color: #555655;
      font-family: $font-family-second;
      font-size: rem(30);
      font-weight: 500;
      line-height: 1.4;

      &.big {
        font-size: rem(48);
        line-height: 1.25;
      }
    }
  }

  .article-logo {
    .logo-wrap {
      display: inline-block;
      vertical-align: middle;
      width: 100%;

      img {
        display: block;
        margin: 0 auto 38px;
        max-width: 80%;

        &:last-of-type {
          margin-bottom: 0;
        }

        &.cross {
          margin-bottom: 50px;
        }
      }
    }
  }

  .article-small-image {
    font-size: 0;
    .full-image {
      width: 100%;
      height: 100%;
    }
  }
/*
  .rightpart {
    .bottom-image {
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
    }
  }
*/

  .article-slider {
    @include row();
//    height: 719px;

    .bxslider{
      @include unstyled();
    }

    li {
      position: relative;
      float: left;
      display: inline-block;
      width: 100%;
      height: 100%;
      font-size: 0;

      &:not(:first-of-type) {
        display: none;
      }
    }

    .bx-viewport li:not(:first-of-type) {
      display: inline-block;
    }

    img {
      width: 100%;
//      margin-bottom: -4px;
    }
/*
    li > img{
      width: 1280px;
      height: 639px;
      margin-bottom: -5px;
    }
*/
    .info{
      width: 100%;
      padding: 28px 10px;
      background-color: #b1a083;
      text-align: center;
      font-family: $font-family-first;
      color: #FFF;
      font-size: rem(18);
      font-weight: 400;
      line-height: 24px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .bx-wrapper, .bx-window {
      height: 100%;
    }

    .bx-prev, .bx-next {
      position: absolute;
      top: calc( 50% - 72px / 2 );
      font-family: $fontello;
      color: #000000;
      font-size: rem(40);
      font-weight: 400;
      @include hoveropacity();
    }

    .bx-prev {
      left: 37px;
    }
    .bx-next {
      right: 37px;
    }
  }

  .article_big {
    width: 100%;
  }

  .columns3 {
    @include row();
    background-color: $white;

    > div {
      box-sizing: border-box;
      display: inline-block;
      vertical-align: top;
    }

    > .colunm-1 {
      width: 33.33%;
    }

    > .colunm-2 {
      width: 66.66%;
    }

    > .colunm-3 {
      width: 100%;
    }

    > .presentation {
      position: relative;
      height: 426px;
      padding: 75px 61px 103px 61px;
      color: #555655;
      background-color: #ffffff;

      h2 {
        margin-top: 0;
        margin-bottom: 5px;
        font-family: $font-family-second;
        font-size: rem(72);
        font-weight: 400;
        line-height: 1;//72px;
      }

      h3 {
        margin-top: 0;
        margin-bottom: 19px;
        font-family: $font-family-second;
        font-size: rem(24);
        font-weight: 400;
        line-height: 1.25;//30px;
      }

      p {
        font-family: $font-family-first;
        font-size: rem(18);
        font-weight: 400;
        line-height: 1.33;//24px;
      }

      a {
        position: absolute;
        bottom: 51px;
        left: 61px;
        right: 61px;
        display: block;
        background-color: #000;
//        width: 304px;
        padding: 12px 0;
//        height: 54px;
        font-family: $font-family-first;
        font-size: rem(20);
        font-weight: 400;
        line-height: 1.5;//54px;
        text-align: center;
        @include hoveropacity(0.7, #FFF);
      }

      .img-wrap {
        position: absolute;
        bottom: 62px;
        left: 0;
        right: 0;
        text-align: center;
        font-size: 0;
        img {
          max-width: 80%;
          max-height: 50px;
        }
        a {
          height: 100%;
        }
      }
      /*
      &.gray {
        background-color:  #3a3a3a;
        color: #FFF;

        a {
          background-color: #181818;
        }
      }

      &.chlorine {
        background-color:  #828b86;
        color: #FFF;

        a {
          background-color:  #3f4d45;
        }
      }

      &.biscuit {
        background-color:  #b4a283;
        color: #FFF;

        a {
          background-color:  #6c5a3a;
        }
      }
      */
    }

    > .subscription {
      position: relative;
      height: 426px;
      padding: 75px 61px 103px 61px;
      color: #555655;
      background-color: #ffffff;

      h3 {
        margin-top: 0;
        margin-bottom: 19px;
        font-family: $font-family-second;
        font-size: rem(24);
        font-weight: 400;
        line-height: 1.25;//30px;
      }

      .title {
        margin-top: 0;
        margin-bottom: 19px;
        font-family: $font-family-second;
        font-size: rem(24);
        font-weight: 400;
        line-height: 1.25;
        text-align: center;
      }
      form {
        position: absolute;
        bottom: 51px;
        left: 61px;
        right: 61px;
        input {
          display: block;
          margin: auto;
          background-color: $subscription-input-bg-color;
          width: 100%;
          text-align: center;
          padding: 14px 10px;
          outline: none;
          border-bottom: none;

          &:not(:first-of-type) {
            border-top: 1px solid #4e4e4e;
          }

          font: {
            family: $subscription-input-ff;
            size: $subscription-input-fz;
          }
          line-height: 1.5;
          color: #FFF;
          @include placeholder() {
            color: $subscription-input-placeholder-color;
          }
        }
        button {
          display: block;
          width: 100%;
          padding: 12px 10px;
          font: {
            family: $subscription-btn-ff;
            size: $subscription-btn-fz;
          }
          line-height: 1.5;
          background-color: $subscription-btn-bg-color;
          color: $subscription-btn-color;
          outline: none;
          border: none;
          @include hoveropacity(0.7, #FFF);
        }
      }
    }


    > .image {
      background-color: #FFF;
      font-size: 0;
      img {
        width: 100%;
        height: auto;
//        margin-bottom: -6px;
      }
    }
  }
}

//.l-main * {
//  font-size: 160%;
//}

.contact-sidebar {
  display: table-cell;
  vertical-align: top;
  width: 33.2%;
  padding: 3.98% 4.84% 3.9% 4.92%;
  background-color: #828b86;
  border-color: #828b86;
}

.city-select {
  margin-bottom: 40px;
  padding: 19px 25px 13px;
  background-color: rgb(64, 77, 68);
  color: #FFF;
  font-family: $font-family-second;
  font-weight: 400;
  line-height: 1.4;//36px;
  text-transform: uppercase;
  display: none;
  opacity: 0;
  width: 240px;
  height: 30px;
  //padding: 0 100px;
  text-indent: 22px;
}

.chosen-container {
  position: relative;
  display: inline-block;
  width: 100% !important;//240px;
  height: 54px;
  margin-bottom: 40px;
  //border: 1px solid $white;
  color: $black;
  font-size: rem(16);
  font-family: $font-family-second;
  letter-spacing: 0.01em;
  font-weight: 400;
  line-height: 1.2;//36px;
  text-transform: uppercase;
  cursor: pointer;
  @include UNSELECTABLE();

  &:after {
    pointer-events: none;
    content: '';
    //background:  no-repeat 50% 50% url('../img/more-down-small.png');
    display: block;
    position: absolute;
    z-index: 100;
    top: 15px;
    right: 31px;
    width: 16px;
    height: 16px;
    border: 2px solid #FFF;
    @include TRANSFORM(rotate(45deg));
  }

  &:before {
    pointer-events: none;
    content: '';
    //background:  no-repeat 50% 50% url('../img/more-down-small.png');
    display: block;
    position: absolute;
    background-color: #404d44;
    z-index: 120;
    top: 0;
    right: 20px;
    width: 40px;
    height: 22px;
  }

  .chosen-single {
    position: relative;
    display: block;
    max-width: 301px;
    height: inherit;
    padding: 21px 60px 19px 24px;
    //@include nohoveropacity();
    @include TRANSITION(color);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    background-color: #404d44;
    color: #FFF;
  }

  .chosen-drop {
    display: none;
  }

  &.chosen-with-drop {
    //chosen-container-active

    &:after {
      top: 25px;
    }

    &:before {
      top: 33px;
    }

    .chosen-single {
      position: relative;
      display: block;
      height: inherit;
      padding: 21px 30px 19px 24px;
      //@include nohoveropacity();
      @include TRANSITION();
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      background-color: #404d44;
      color: #FFF;
    }

    .chosen-results {
      //border-width: 0 1px 1px 1px;
      //border-style: solid;
      //border-color: $white;
      list-style: none;
      margin: 0;
      padding: 0;
      color: #FFF;

      .active-result {
        padding: 21px 30px 19px 24px;

        &:hover {
          color: #d2b585;
        }
      }
    }

    .chosen-drop {
      display: block;
      position: absolute;
      top: 0; //calc( 100% + 1px );
      left: 0;
      right: 0;
      background-color: #404d44;

      .chosen-search {
        display: none;
      }

      .filter__steps__item {
        padding: 6px 11px 6px 11px;

        &.highlighted {
          color: $white;
          background-color: lighten(#404d44, 0.5);
        }
      }
    }
  }

  &.customSelect {
    @include TRANSITION();
  }

  &:hover .chosen-single {
    color: #d2b585;
  }
}

.type-list {

}

.type {
  display: block;
  padding: 14px 0 12px;
  border-bottom: 1px solid #9aa39e;
  color: #FFF;
  font-family: $font-family-second-light;
  font-size: rem(15);
  font-weight: 300;
  line-height: 1.4;//36px;

  &__link {
    display: inline-block;
    color: inherit;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  &.active {
    color: #555655;
  }

  &:first-of-type {
    border-top: 1px solid #9aa39e;
  }
}

.contact-wrap {
  display: table-cell;
  vertical-align: top;
  width: 66.8%;
  font-size: 0;

  .contact {
    display: inline-block;
    vertical-align: top;
    width: 50%;
  }
}

.contact {
  position: relative;
  height: 439px - 132px;
  padding: 48px 61px ( 75px - 50px ) 66px;
  color: #555655;
  background-color: #ffffff;
  //border-bottom: 1px solid #efefef;

  //&:nth-child(3n+2), &:nth-child(3n+3) {
    //border-left: 1px solid #efefef;
  //}

  h3 {
    margin-top: 0;
    margin-bottom: 22px;
    font-family: $font-family-second;
    font-size: rem(23);
    letter-spacing: 0.022em;
    font-weight: 400;
    line-height: 1.2;//36px;
    text-transform: uppercase;
  }
/*
  p {
    font-family: $font-family-first;
    font-size: rem(18);
    font-weight: 400;
    line-height: 1.33;//24px;
  }
  */
  .a-wrap {
    position: absolute;
    bottom: 28px;
    left: 0;//63px;
    right: 0;
//        width: 304px;
    text-align: center;
  }

  .a-wrap {
    display: none;
  }

  a {
    display: inline-block;
    font-family: $fontello;
    font-size: 1.1rem;
    font-weight: 400;
    line-height: 1;//36px;
    text-align: center;
    color: inherit;
    text-decoration: none;
    @include TRANSITION(color);

    &:hover {
      color: #701F38;
    }
//        @include hoveropacity();
  }

  a.link {
    display: inline-block;
    font-family: inherit;
    font-size: 0.9375rem;
    font-weight: 400;
    line-height: 1;//36px;
    text-align: center;
    color: inherit;
    text-decoration: none;
    @include TRANSITION(color);

    &:hover {
      color: #701F38;
    }
  }

  //&.active {
  //  background-color: #828b86;
  //  border-color: #828b86;
  //  color: #FFF;
  //
  //  .details > div {
  //    border-color: #9ba29e;
  //  }
  //
  //  a {
  //    transform: scale(1,-1);;
  //  }
  //}

  .details {
    > div {
      position: relative;
      padding: 12px 20px 13px 40px;
      border-width: 0 0 1px 0;
      border-style: solid;
      border-color: #dddddd;
      font-family: $font-family-first;
      font-size: rem(15);//16
      font-weight: 400;
      line-height: 1.5;//24px;

      &:first-of-type {
        border-width: 1px 0 1px 0;
      }

      b {
        position: absolute;
        top: 0;
        left: 0;
//            margin-right: 23px;
        font-family: $fontello;
        font-size: rem(24);
        font-weight: 400;
        line-height: 2.0833;//50px;
      }

      img {
        position: absolute;
        top: 18px;
        left: 0;
        max-width: 17px;
        max-height: 17px;
      }
    }
  }
}
#modal {
//  display: none;
  opacity: 1;
  position: fixed;
  z-index: 30;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  min-width: $min-width;
  min-height: $min-body-height;
  background-color: rgba(42, 42, 42, 0.71);
  @include TRANSITION(opacity, 0.5s);

  &.active {
    opacity: 1;
  }

  &:before {
    position: absolute;
    z-index: -1;
    content: '';
    display: block;
    $encr: 35px;
    top: calc( 15% - #{$encr} );
    bottom: calc( 15% - #{$encr} );
    left: calc( 15% - #{$encr} );
    right: calc( 15% - #{$encr} );
    border-radius: 50px;
    background-color: #828b86;//rgb(243, 126, 74);
  }
}

.edit-container {
  position: absolute;
  top: 15%;
  bottom: 15%;
  left: 15%;
  right: 15%;
  margin-right: 10px;
  padding: 0 20px;
  background-color: #828b86;//rgb(243, 126, 74);
  overflow-y: auto;

  label {
    cursor: pointer;

    p {
      margin: 0;
      padding-bottom: 10px;
      @include hoveropacity(0.6);
    }
  }

  input, textarea {
    display: block;
    width: 100%;
    margin-bottom: 10px;
    padding: 5px 10px;
    font-size: rem(20);
    line-height: 1.2;
    background-color: $white;
  }

  input[type=file] {
    padding: 0;
    font-size: rem(16);
    background-color: transparent;
  }
}

.modal-name {
  color: #FFF;
  font-size: rem(30);
  line-height: 1.4;
  margin-bottom: 20px;
}

.modal-close {
  position: absolute;
  top: 50px;
  right: 50px;
  @include hoveropacity();

  img {
    max-width: 50px;
    max-height: 30px;
  }
}

.modal-save {
  display: inline-block;
  width: 100%;
  margin-bottom: 0 !important;
  padding: 13px 40px;
  background-color: #000 !important;
  font-family: $font-family-first;
  font-size: rem(18);
  font-weight: 400;
  line-height: 20px;
  text-align: center;
  @include hoveropacity(0.7, #FFF);
}

.add-blocks {
  padding: 20px 30px;
  background-color: rgba(130, 139, 134, 0.6);

  span {
    color: #000000;
    margin-right: 20px;
    font-size: rem(24);
    line-height: 1.4;
    display: inline-block;
    vertical-align: middle;
  }

  select {
    display: inline-block;
    vertical-align: middle;
    max-width: 600px;
    cursor: pointer;
  }

  a {
    display: inline-block;
    vertical-align: middle;
    margin-left: 20px;
  }

  a {
    display: inline-block;
    //        margin-bottom: 20px;
    padding: 10px 15px;
    @include hoveropacity();
    background-color: rgba(0, 0, 0, 0.4);
    border-radius: 15px;
  }

  img {
    width: auto;
    max-width: 25px !important;
    max-height: 15px !important;
    margin-bottom: 0;
    @include transform(none);
  }
}

.browser {
  position: relative;
  z-index: 20;
  padding: 76px 100px 90px;
  text-align: center;
  line-height: 1.5;
  background-color: #FFF;
  color: #FFF;

  &__title {
    width: 518px;
    margin: 0 auto 16px;
    font-family: $font-family-second;
    color: #555655;
    font-size: rem(36);
    font-weight: 500;
    line-height: 1.28;
    text-transform: uppercase;
  }

  &__text {
    width: 518px;
    margin: 0 auto 17px;
    font-family: $font-family-first;
    color: #555655;
    font-size: rem(18);
    font-weight: 400;
    letter-spacing: -0.05em;
  }

  &__whatever {
    display: inline-block;
    background-color: #555655;
    padding: 12px 40px;
    font-family: $font-family-first;
    color: #FFF;
    font-size: rem(20);
    font-weight: 400;
    letter-spacing: -0.01em;
    @include hoveropacity();
  }
}

.browser-list {
  margin: 0 0 81px 0;
  padding: 0;
  list-style: none;
  font-size: 0;
}

.browser-item {
  display: inline-block;
  margin-right: 13px;
  margin-bottom: 8px;
  color: #555655;

  &:last-of-type {
    margin-right: 0;
  }

  &__link {
    display: block;
    @include hoveropacity();
  }

  &__tile {
    display: table-cell;
    vertical-align: middle;
    background-color: #d2b585;
    width: 120px;
    height: 120px;
  }

  &__text {
    margin-top: 14px;
    font-family: $font-family-first;
    color: #555655;
    font-size: rem(18);
    font-weight: 400;
    letter-spacing: -0.04em;
  }
}

@media screen and  ( max-width: 1294px ) {
  body {
    min-width: $mid-width;
  }
  .nav {
    @include span-columns(9.5 of 12);
    display: table;
  }
  .nav a {
    font-size: rem(14);
  }
  .shopping-basket {
    @include span-columns(1.4 of 12);
    margin-left: 50px;
  }
  .shopping-basket .amount {
    font-size: rem(15);
  }
  .l-header .l-signature {
    left: 204px;
    width: 620px;
    font-size: rem(13);
  }

  .l-main .slider {
    li {
      width: 1000px !important;
      height: 500px;
    }
    .bx-pager {
      //      left: calc(75% + 25px);
    }
    .info {
      bottom: calc(12.9% - 7px); //50px;
      left: 50px;
      //      padding: 60px 25px 20px;
      .title {
        font-size: rem(120);
      }
      .desc {
        font-size: rem(40);
      }
    }
  }

  .l-main .columns3 {
    .presentation, .subscription {
      height: 333px;
      padding: 30px 37px 95px 37px;

      h2 {
        font-size: rem(60);
      }
      h3, .title {
        font-size: rem(20);
      }
      p {
        font-size: rem(16);
      }
      a {
        bottom: 30px;
        left: 37px;
        right: 37px;
        font-size: rem(17);
      }
      .img-wrap {
        bottom: 35px;
      }
      form {
        bottom: 30px;
        left: 37px;
        right: 37px;

        input {
          padding: 12px 10px;
          font-size: rem(14);
        }

        button {
          font-size: rem(17);
        }
      }
    }
    .image {
      height: 333px;
    }
  }

  .l-main .search-wrap {
    input {
      font-size: rem(19);
    }
    .filters-container {
      //      width: 1192px;
      //      margin: 27px auto 0 auto;
      //      font-size: rem(16);
      //      line-height: 30px;
    }
  }

  .l-main {
    .vendors-container .vendor {
      height: 333px;
      padding-bottom: 95px;

      .origin {
        top: 250px;
        font-size: rem(14);
        line-height: 18px;
        b {
          font-size: rem(20);
        }
      }
    }
  }

  .l-main .catalog-container .load-container {
    min-height: 334px;
    padding: ( 333px - 196px ) / 2 0;
  }

  .l-main .catalog-container .wine {
    height: 333px;
    padding: 30px 20px 0 20px;

    img {
//      max-width: 38%; //85px;
      max-height: 270px;
    }

    .name {
      max-width: 128px;
      margin-bottom: 12px;
      font-size: rem(15);
      line-height: 20px;
    }

    .origin {
      max-width: 128px;
      font-size: rem(13);
      line-height: 18px;
    }

    .bottom {
      top: 202px;
      left: 20px;
      right: 20px;

      .cost {
        margin-top: 20px;
        font-size: rem(16);
        line-height: 21px;
        word-spacing: -0.1em;
        b {
          font-size: rem(15);
        }
      }

      .buy {
        margin-top: 5px;
        .tocart {
          font-size: rem(32);
        }
        .count {
          //            width: 83px;
          height: 54px;
          margin-top: 6px;
          font-size: rem(14);
          line-height: 54px;//26px;
          border-radius: 27px;

          .minus, .plus {
            display: inline-block;
            width: 38px;
//            background: none;
            text-align: center;
          }
          .minus {
            border-top-left-radius: 27px;
            border-bottom-left-radius: 27px;
          }
          .plus {
            border-top-right-radius: 27px;
            border-bottom-right-radius: 27px;
          }
        }
      }
    }
  }

  .l-main .catalog-pager {
    padding: 20px 20px;
    &__prev, &__next, &__link {
      font-size: rem(14);
    }
    &__link {
      padding: 2px 4px 0;
    }
  }

  .l-main .new {
    height: auto;
//    height: 334px;
//    height: 100%;

    .new-desc {
      padding: 35px 30px 10px 40px;
      .date {
        font-size: rem(14);
        line-height: 32px;

        b {
          font-size: rem(60);
          line-height: 40px;
        }
      }
      h1 {
        margin-top: 6px;
        font-size: rem(18);
        line-height: 27px;
      }
      p {
        font-size: rem(16);
        line-height: 20px;
      }
    }
  }

  .l-main .features-wrap {
//    .features-container {
//      width: 60%; //62.5%;
//    }
//    .image {
//      width: 40%; //37.5%;
//    }
    .features, .image {
      min-height: 1000px;
//      height: 100px;
    }

    .features {
      padding: 30px 30px;

      .name {
        font-size: rem(22);
        line-height: 38px;
      }

      .cost {
        margin-bottom: 25px;
        font-size: rem(50);
        line-height: 38px;
        word-spacing: -0.1em;
        b {
          font-size: rem(50);//rem(38);
        }
      }

      .attribute {
        width: 40%;
        padding: 10px 0;
        font-size: rem(14);
        line-height: 20px;

        &:nth-child(2n) {
          width: 53%;
          margin-left: 25px;
        }
      }

      p {
        font-size: rem(14);
        line-height: 1.8;

        &.bold {
          font-size: rem(17);
        }
      }

      div {
        font-size: rem(14);
        line-height: 1.8;
      }

      .tocart {
        height: 36px;
        margin-bottom: 50px;
        //        margin-top: 24px;
        font-size: rem(16);
        line-height: 36px;
      }
      .count {
        height: 54px;
        margin-bottom: 32px;
        font-size: rem(16);
        line-height: 54px;
      }
    }

    .description {
      padding: 60px 30px 20px 30px;

      .close {
        top: 26px;
        right: 31px;
        font-size: rem(32);
      }

      p {
        font-size: rem(14);
        line-height: 21px;
      }
    }

    .image {
      padding: 30px 0;

      img {
//        max-width: 350px;
//        max-height: 458px;
      }
    }
  }

  .l-main .order-info {
    min-height: 580px;
    padding: 35px 30px;

    &.full {
      width: 100%;
      padding: 35px 58% 35px 30px;

      .order-text {
        width: 85%;
      }
    }

    .order-text {
      font-size: rem(21);
      line-height: 24px;
    }

    .order-total-cost {
      margin-top: 18px;
      font-size: rem(50);
      line-height: 38px;
      word-spacing: -0.1em;

      b {
        font-size: rem(50);
      }
    }

    .go {
      margin-top: 28px;
      padding: 11px 30px;
      font-size: rem(15);
      line-height: 18px;
    }

    .order-details {
      margin-top: 18px;
      font-size: rem(14);
      line-height: 21px;
    }

    .contact-details {
      padding: 40px 38px 70px 38px;

      label {
        margin-bottom: 30px;
        span {
          font-size: rem(14);
          line-height: 22px;
        }
        input, textarea {
          height: 36px;
          padding: 0 13px;
          font-size: rem(14);
        }
        textarea {
          min-height: 54px;
          max-height: 150px;
          padding: 8px 13px;
        }
      }

      .go {
        margin-top: 20px;
        padding: 8px 80px;
      }
    }
  }

  .l-main {
    .article-image {
      font-size: 0;
      .back {
        top: 24px;
        left: 20px;
        font-size: rem(14);
        line-height: 26px;

        b {
          font-size: rem(22);
        }
      }

      .change {
        top: 24px;
        left: 120px;
        font-size: rem(16);
        line-height: 26px;

        b {
          font-size: rem(32);
        }
      }

      .info {
        bottom: calc(12.9% - 7px); //50px;
        left: 50px;
        //      padding: 60px 25px 20px;
        .title {
          font-size: rem(120);
        }
        .desc {
          font-size: rem(40);
        }
      }
    }

    .article-tocatalog {
      padding: 68px 0;

      .desc {
        font-size: rem(25);
        line-height: 38px;
      }

      .go {
        margin-top: 22px;
        padding: 8px 100px;
        font-size: rem(16);
      }
    }

    .article-entry, .article-entry-big, .article-link {
      height: $row-height-middle;
      padding: 25px 30px 20px 30px;
    }

    .article-entry, .article-entry-big {
      p {
        margin: 18px 0 0 0;
        font-size: rem(15);
        line-height: 1.5;
//        line-height: 18px;

        &.big {
          font-size: rem(18);
          line-height: 1.5;

          & + p {
            margin-top: 34px;
          }
        }
      }
      ol {
        font-size: rem(15);
        line-height: 1.5;
//        line-height: 1.3;
//        li:before {
//          top: 5px;
//        }
      }
    }

    .article-entry-big {
      height: 2 * $row-height-middle;
    }

    .article-entry {
      height: 100%;
    }

    .article-link {
      padding-right: 50px;

      .desc-wrap {
        .desc {
          font-size: rem(32);
        }

        .go {
          bottom: 40px;
          left: 30px;
          right: 30px;
          font-size: rem(15);
          line-height: 20px;
        }
      }
    }

    .article-quote, .article-logo, .article-small-image {
      height: $row-height-middle;
    }

    .article-quote {
      .quote {
        padding: 37px 30px;
        font-size: rem(26);

        &.big {
          font-size: rem(36);
        }
      }
    }

    .article-logo {
      img {
        max-width: 70%;
        margin-bottom: 5px;
        @include TRANSFORM(scale(0.7, 0.7));

        &:last-of-type {
          margin-bottom: 0;
        }

        &.cross {
          margin-bottom: 40px;
        }
      }
    }

    .article-slider {
      .info {
        padding: 23px 60px;
        font-size: rem(16);
        line-height: 21px;
      }
      .bx-prev, .bx-next {
        bottom: 16px;
        font-size: rem(36);
      }
    }

    .contacts {
      height: 281px;

      .contact {
        padding: 20px 230px 20px 30px;

        .city {
          margin-bottom: 12px;
          font-size: rem(16);
          line-height: 26px;
        }

        p, b {
          font-size: rem(14);
          line-height: 18px;
        }
        p {
          margin-bottom: 15px;
        }

        b {
          line-height: 18px;
        }

        .send {
          top: 23px;
          right: 20px;
          padding: 7px 28px;
          font-size: rem(15);
          line-height: 22px;
        }
      }
    }

    .columns3 > .contact, .contact-wrap > .contact {
      position: relative;
      height: 260px;
      padding: 36px 30px 30px 30px;

      h3 {
        margin-bottom: 12px;
        font-size: rem(20);
      }
      .a-wrap {
        bottom: 15px;
      }

      a {
        font-size: rem(30);
      }

      .details {
        > div {
          padding: 10px 18px 10px 36px;
          font-size: rem(13);

          b {
//            top: 0;
//            left: 0;
            font-size: rem(20);
          }

          img {
            top: 15px;
            left: 0;
            max-width: 14px;
            max-height: 14px;
          }
        }
      }
    }

    #map {
      height: 663px;
    }
  }

  .chosen-container {
    margin-bottom: 35px;
    font-size: rem(14);
    .chosen-single, .chosen-drop {
      width: 234px;
      max-width: 234px;
    }
  }

  .type {
    padding: 12px 0 10px;
    font-size: rem(13);
  }
}

@media screen and ( max-width: 1014px ) {
  body {
    min-width: $min-width;
  }

  .l-header .l-nav-bar{
    @include span-columns(10.2);
    @include omega();
  }
  .nav {
    @include span-columns(9.9 of 12);
    display: table;
  }
  .nav a {
    font-size: rem(12);
  }
  .shopping-basket {
    @include span-columns(0.8  of 12);
  }
  .shopping-basket .amount {
    font-size: rem(13);
  }
  .l-header .l-brand {
    @include span-columns(1.8);
  }
  .logo {
    margin-left: 10px;
  }
  .l-header .l-signature {
    left: 110px;
    width: 501px;
    font-size: rem(12);
  }

  .l-main .slider {
    li {
      width: 720px !important;
      height: 360px;
    }
    .bx-pager {
//      left: calc(50% + 20px);
    }
    .info {
      bottom: calc( 12.9% + 2px );//35px;
      left: 35px;
      .title {
        font-size: rem(90);
      }
      .desc {
        padding-top: 10px;
        font-size: rem(25);
      }
    }
  }

  .l-main .columns3 {
    .presentation, .subscription {
      height: 240px;
      padding: 16px 20px 60px 20px;

      h2 {
        font-size: rem(40);
      }
      h3, .title {
        font-size: rem(16);
      }
      p {
        font-size: rem(12);
      }
      a {
        bottom: 16px;
        left: 20px;
        right: 20px;
        font-size: rem(12);
      }
      .img-wrap {
        bottom: 30px;
      }
      form {
        bottom: 16px;
        left: 20px;
        right: 20px;

        input {
//          padding: 8px 10px;
          font-size: rem(10);
        }

        button {
          font-size: rem(12);
        }
      }
    }
    .image {
      height: 240px;
    }
  }

  .l-main .search-wrap {
    padding: 27px 30px 30px 30px;
    input {
      font-size: rem(16);
    }
    .full-string {
      a {
        font-size: rem(14);
        display: inline-block;
        max-width: 24%; //40%;
      }
      .reset {
        top: 2px;
      }
    }
    .filters-container {
      font-size: rem(14);
      //      line-height: 30px;
      .filter-name {
        max-width: 57px;//76px;
        padding-right: 5px;
      }
      .filter-param {
        a {
          display: inline-block;
          max-width: 30%; //40%;
        }
      }
    }
  }

  .l-main {
    .vendors-container .vendor {
      height: 245px;
      padding-bottom: 70px;

      .origin {
        top: 180px;
        font-size: rem(12);
        line-height: 15px;
        b {
          font-size: rem(17);
        }
      }
    }
  }

  .l-main .catalog-container .load-container {
    min-height: 241px;
    padding: ( 240px - 196px ) / 2 0;
  }

  .l-main .catalog-container .wine {
    height: 240px;
    padding: 15px 15px 0 15px;

    img {
      max-width: 31%;
      max-height: 210px;
    }

    .name {
      max-width: 100px;
      margin-bottom: 10px;
      font-size: rem(12);
      line-height: 16px;
    }

    .origin {
      max-width: 100px;
      font-size: rem(12);
      line-height: 16px;
    }

    .bottom {
      top: 152px;
      left: 15px;
      right: 15px;

      .cost {
        margin-top: 12px;
        font-size: rem(16);
        line-height: 21px;
        word-spacing: -0.1em;
        b {
          font-size: rem(15);
        }
      }

      .buy {
        margin-top: 0;
        .tocart {
          height: 40px;
          width: 40px;
          font-size: rem(20);
          line-height: 40px;
        }
        .count {
          height: 40px;
          margin-top: 5px;
          font-size: rem(14);
          line-height: 40px;
          border-radius: 20px;

          .minus, .plus {
            display: inline-block;
            width: 28px;
//            background: none;
            text-align: center;
            font-size: rem(14);
          }
          .minus {
            border-top-left-radius: 20px;
            border-bottom-left-radius: 20px;
          }
          .plus {
            border-top-right-radius: 20px;
            border-bottom-right-radius: 20px;
          }
        }
      }
    }
  }

  .l-main .catalog-pager {
    padding: 10px 10px;
    &__prev, &__next, &__link {
      font-size: rem(12);
    }
    &__link {
      padding: 2px 4px 0;
    }
  }

  .l-main .new {
    height: auto;
//    height: 276px;
//    height: 100%;

    .new-desc {
      padding: 25px 30px 10px 30px;
      .date {
        font-size: rem(12);
        line-height: 26px;
        b {
          font-size: rem(40);
          line-height: 26px;
        }
      }
      h1 {
        margin-top: 4px;
        font-size: rem(16);
        line-height: 18px;
      }
      p {
        font-size: rem(14);
        line-height: 16px;
      }
    }
  }

  .l-main .features-wrap {
//    .features-container {
//      width: 60%; //62.5%;
//    }
//    .image {
//      width: 40%; //37.5%;
//    }
    .features, .image {
      min-height: 820px;
      height: 100px;
    }

    .features {
      padding: 25px 25px;

      .name {
        font-size: rem(18);
        line-height: 30px;
      }

      .cost {
        margin-bottom: 25px;
        font-size: rem(45);
        line-height: 32px;
        word-spacing: -0.1em;
        b {
          font-size: rem(45);//rem(38);
        }
      }

      .attribute {
        width: 40%;
        padding: 6px 0;
        font-size: rem(11);
        line-height: 20px;

        &:nth-child(2n) {
          width: 52%;
          margin-left: 15px;
        }
      }

      p {
        font-size: rem(12);
        line-height: 1.5;

        &.bold {
          margin-bottom: 20px;
          font-size: rem(15);
        }
      }

      div {
        font-size: rem(12);
        line-height: 1.5;
      }

      .tocart {
        height: 32px;
        margin-bottom: 25px;
        //        margin-top: 24px;
        font-size: rem(14);
        line-height: 32px;
      }
      .count {
        width: 119px;
        height: 40px;
        margin-bottom: 17px;
        font-size: rem(14);
        line-height: 40px;

        .minus, .plus {
          width: 28px;
        }
        .pieces {
          width: 59px;
          line-height: 40px;
        }
      }
    }

    .description {
      padding: 60px 30px 20px 30px;

      .close {
        top: 26px;
        right: 31px;
        font-size: rem(32);
      }

      p {
        font-size: rem(14);
        line-height: 21px;
      }
    }

    .image {
      padding: 20px 0;

      img {
        max-width: 64%;
//        max-height: 458px;
      }
    }
  }

  .l-main .order-info {
    min-height: 500px;
    padding: 25px 20px;

    &.full {
      width: 100%;
      padding: 35px 58% 35px 30px;

      .order-text {
        width: 85%;
      }
    }

    .order-text {
      font-size: rem(16);
      line-height: 19px;
    }

    .order-total-cost {
      margin-top: 10px;
      font-size: rem(38);
      line-height: 26px;
      word-spacing: -0.1em;

      b {
        font-size: rem(38);
      }
    }

    .go {
      margin-top: 20px;
      padding: 9px 20px;
      font-size: rem(12);
      line-height: 16px;
    }

    .order-details {
      margin-top: 14px;
      font-size: rem(12);
      line-height: 18px;
    }

    .contact-details {
      padding: 35px 30px 32px 30px;

      label {
        margin-bottom: 24px;
        span {
          font-size: rem(12);
          line-height: 20px;
        }
        input, textarea {
          height: 32px;
          padding: 0 11px;
          font-size: rem(12);
        }
        textarea {
          min-height: 44px;
          max-height: 100px;
          padding: 6px 13px;
        }
      }

      .go {
        margin-top: 10px;
        padding: 7px 50px;
      }
    }
  }

  .l-main {
    .article-image {
      .back {
        top: 20px;
        left: 16px;
        font-size: rem(12);
        line-height: 22px;

        b {
          font-size: rem(18);
        }
      }

      .change {
        top: 20px;
        left: 96px;
        font-size: rem(14);
        line-height: 22px;

        b {
          font-size: rem(32);
        }
      }

      .info {
        bottom: calc( 12.9% + 2px );//35px;
        left: 35px;
        .title {
          font-size: rem(90);
        }
        .desc {
          padding-top: 10px;
          font-size: rem(25);
        }
      }
    }

    .article-tocatalog {
      padding: 45px 0;

      .desc {
        font-size: rem(18);
        line-height: 30px;
      }

      .go {
        margin-top: 18px;
        padding: 2px 70px;
        font-size: rem(11);
      }
    }

    .article-entry, .article-entry-big, .article-link {
      height: $row-height-min;
      padding: 15px 15px 15px 15px;
    }

    .article-entry, .article-entry-big {
      p {
        margin: 16px 0 0 0;
        font-size: rem(11);

        &.big {
          font-size: rem(14);
          line-height: 20px;

          & + p {
            margin-top: 15px;
          }
        }
      }
      ol {
        font-size: rem(11);
        li:before {
          right: calc( 100% + 5px);
//          font-size: rem(15);
          top: 3px;
        }
      }
    }

    .article-entry-big {
      height: 2 * $row-height-min;
    }

    .article-entry {
      height: 100%;
    }

    .article-link {
      .desc-wrap {
        .desc {
          font-size: rem(26);
        }

        .go {
          left: 15px;
          right: 15px;
          bottom: 20px;
          font-size: rem(14);
          line-height: 18px;
        }
      }
    }

    .article-quote, .article-logo, .article-small-image {
      height: $row-height-min;
    }
    .article-quote {
      .quote {
        padding: 24px 10px;
        font-size: rem(19);

        &.big {
          font-size: rem(28);
        }
      }
    }

    .article-logo {
      .logo-wrap {
        img {
          max-width: 60%;
          margin-bottom: 0;

          &:last-of-type {
            margin-bottom: 0;
          }

          &.cross {
            margin-bottom: 20px;
          }
        }
      }
    }

    .article-slider {
      .info {
        padding: 18px 30px;
        font-size: rem(14);
        line-height: 19px;
      }
      .bx-prev, .bx-next {
        bottom: 10px;
        font-size: rem(32);
      }
    }

    .contacts {
      height: 201px;

      .contact {
        padding: 12px 155px 5px 15px;

        .city {
          margin-bottom: 6px;
          font-size: rem(13);
          line-height: 18px;
        }

        p, b {
          font-size: rem(11);
          line-height: 14px;
        }
        p {
          margin-bottom: 9px;
        }

        b {
          line-height: 14px;
        }

        /*
                p, b {
                  font-size: rem(14);
                  line-height: 18px;
                }
                p {
                  margin-bottom: 15px;
                }

                b {
                  line-height: 18px;
                }
        */

        .send {
          max-width: 100px;
          top: 17px;
          right: 20px;
          padding: 5px 10px;
          font-size: rem(12);
          line-height: 18px;
        }
      }
    }

    .columns3 > .contact, .contact-wrap > .contact {
      position: relative;
      height: 190px;
      padding: 25px 20px 20px 20px;

      h3 {
        margin-bottom: 6px;
        font-size: rem(15);
      }
      .a-wrap {
        bottom: 8px;
      }

      a {
        font-size: rem(24);
      }

      .details {
        > div {
          padding: 5px 15px 5px 30px;
          font-size: rem(12);

          b {
//            top: 0;
//            left: 0;
            font-size: rem(13);
          }

          img {
            top: 10px;
            left: 0;
            max-width: 10px;
            max-height: 10px;
          }
        }
      }
    }

    #map {
      height: 477px;
    }
  }

  .chosen-container {
    margin-bottom: 30px;
    font-size: rem(12);
    .chosen-single, .chosen-drop {
      width: 170px;
      max-width: 170px;
    }
  }

  .type {
    padding: 10px 0 8px;
    font-size: rem(11);
  }
}