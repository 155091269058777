.l-header{
  @include container();
  position: relative;
  z-index: 10;
  height: 130px;
//  padding-bottom: 20px;
  background-color: $white;

  .l-brand{
    @include span-columns(2.41);
  }

  .l-nav-bar{
    @include span-columns(9.5);
    @include omega();
  }

  .l-signature{
    position: absolute;
    top: 96px;
    left: 263px;
    width: 753px;
    font-family: $font-family-second;
    color: #555655;
    font-size: rem(14);
    font-weight: 400;
    line-height: 20px;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &:after {
    position: absolute;
    z-index: 1;
    top: 100%;
    left: 0;
    right: 0;
    display: block;
    height: 22px;
    content: '';
    background: none;
    @include background(linear-gradient(0deg, rgba(255, 255, 255, 0) 0%, rgba(0, 0, 0, 0.2) 99%, rgba(0, 0, 0, 1) 100%));
  }
}

.logo{
  display: block;
  margin-top: 33px;
  margin-left: 45px;
  width: 100px;
  @include TRANSITION(opacity);

  &:hover {
    opacity: 0.7;
  }
  img{
    max-width: 100%;
    height: auto;
  }
}

.nav{
  @include span-columns(9.03 of 12);//(11.0 of 12);

  display: table;
  padding-top: 31px;
  margin-right: 0;
  @include unstyled($pad: false);
  padding-left: 7px;
  padding-bottom: 18px;
  border-bottom: 1px solid #d9d9d9;
  text-align: center;
  li{
    display: table-cell;
  }

  a{
    text-decoration: none;
    font: {
      family: $font-family-second;
      size: rem(16);
      weight: 400;
    }
    line-height: 28px;
    color: #555655;
    text-transform: uppercase;
    @include hoveropacity;

    &.active {
      color: #828b86;
    }
  }
}

.shopping-basket{
  display: block;
  @include span-columns(1.0 of 12);//span-columns(1.4 of 12);
  @include omega();
  margin-top: 29px;
  margin-left: 171px;
  position: relative;
  text-decoration: none;
  @include hoveropacity;
  @include TRANSITION(opacity);

  .amount{
    position: relative;
    float: right;
//    width: 25px;
    height: 25px;
    color: #fff;
    text-align: left;

    font: {
      family: $font-family-first;
      size: rem(14);
    }
    line-height: 15px;
//    padding-left: 2px;//49px;

    .count {
      position: relative;
      z-index: 1;
      padding: 5px 9px;
      display: block;
      border-radius: 25px;
      background-color: #d2b585;
    }


    b {
      position: absolute;
      z-index: 0;
      top: 8px;
      left: -40px;
      display: inline-block;
      width: 64px;
      height: 64px;
      border-radius: 50%;
      background-color: #f9f9f9;
      vertical-align: middle;
      margin-right: 10px;
      font-family: $fontello;
      font-size: rem(33);
      font-weight: 400;
      line-height: 64px;
      color: #555655;
      text-align: center;
    }
  }

  &.active {
    .count {
      visibility: hidden;
    }
    b {
      background-color: #828b86;
      color: #FFF;
    }
  }
/*
  &:before{
    content: '';
    background: url("../img/basket.png") no-repeat 0 0;
    width: 25px;
    height: 25px;
    position: absolute;
    top: 0;
    left: 15px;
  }
*/
}