@font-face {
  font-family: 'fontello';
  src: url('../font/fontello.eot'); /* IE9 Compat Modes */
  src: url('../font/fontello.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../font/fontello.woff') format('woff'), /* Modern Browsers */
       url('../font/fontello.ttf')  format('truetype'), /* Safari, Android, iOS */
       url('../font/fontello.svg#b15484b6f0093f1644d9892a0cdac0bf') format('svg'); /* Legacy iOS */

  font-style:   normal;
  font-weight:  400;
}

@font-face {
  font-family: 'PTSans';
  src: url('../font/NotoSerif.ttf');
  src: url('../font/NotoSerif.otf') format('otf'), url('../font/NotoSerif.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'FuturaDemiC';
  src: url('../font/FuturaDemiC.ttf');
  src: url('../font/FuturaDemiC.otf') format('otf'), url('../font/FuturaDemiC.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}
/*
@font-face {
  font-family: 'Fedra-Light';
  src: url('../font/FedraSerifPro_A_Book.otf') format('off');
  font-weight: 300;
  font-style: normal;
}
*/
//@font-face {
//  font-family: 'fontello';
//  src: url('../font/fontello.ttf');
//  font-weight: 400;
//  font-style: normal;
//}

@font-face {
  font-family: 'PTRoubleSerif';
  src: url('../font/RUBSN.otf');
  font-weight: 400;
  font-style: normal;
}


@mixin maket($widht: 1280px, $height: 1000px, $name: 'home.jpg', $opacity: 0.5){
  width: $widht;
  height: $height;
  background: url("../img/" + $name) no-repeat center 0;
  opacity: $opacity;
  position: absolute;
  top: 0;
  left: 50%;
  margin-left: -$widht / 2;
  z-index: -1;
}

@mixin unstyled($pad: true, $marg: true){
  list-style: none;
  @if $pad  {
    padding-left: 0;
  }
  @if $marg {
    margin-top: 0;
    margin-bottom: 0;
  }
}

@mixin container($local-width: $max-width){
  @include clearfix;
//  width: $local-width;
  margin: {
    left: auto;
    right: auto;
  }
  width: $local-width;
  @media screen and  ( max-width: 1294px ) {
    width: $mid-width;
  }

  @media screen and  ( max-width: 1014px ) {
    width: $min-width;
  }
}
.helper {
  display: inline-block;
  height: 100%;
  width: 0;
  vertical-align: middle;
}

@mixin hoveropacity($opacity: 0.7, $color: inherit){
  color: $color;
  text-decoration: none;
  @include TRANSITION();
  &:hover {
    opacity: $opacity;
  }
}

@mixin TRANSITION($property:all, $duration:0.2s, $timing-function:ease-out, $transition-delay:0s){
  -webkit-transition: $property $duration $timing-function $transition-delay;
  -moz-transition: $property $duration $timing-function $transition-delay;
  -ms-transition: $property $duration $timing-function $transition-delay;
  -o-transition: $property $duration $timing-function $transition-delay;
  transition: $property $duration $timing-function $transition-delay;
}

@mixin TRANSFORM($function) {
  -webkit-transform: $function;
  -moz-transform: $function;
  -ms-transform: $function;
  -o-transform: $function;
  transform: $function;
}

@mixin allspace($position: absolute){
  position: $position;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

html, body {
  height: 100%;
}

body{
  position: relative;
  min-width: $min-body-width;
  background-color: #fff;
  min-height: $min-body-height;
  font-family: $font-family-first;
//  &:after{
//    content: '';
//    @include maket($height: 1360px);
//  }
}