// To develop
// TODO: disable Development Tools

$visual-grid: false !default;
$visual-grid-color: #bfbfbf !default;
$visual-grid-index: back !default;
$visual-grid-opacity: 0.8 !default;
$min-body-width: 1280px;
$min-body-height: 600px;
$row-height: 640px;
$row-height-middle: 500px;
$row-height-min: 360px;
$reload-color: #701F38;


@mixin pick-out($color: #000){
  outline: 1px solid $color;
}

$font-family-first: 'PTSans', sans-serif;
$font-family-first-medium: $font-family-first;
$font-family-second: 'FuturaDemiC', sans-serif;
$font-family-second-light: $font-family-second;
$fontello: 'fontello';
$font-rouble: 'PTRoubleSerif';


// Grid

$column: 100px;
$gutter: 30px;
$grid-columns: 12;
$max-width: rem(1280);
$mid-width: rem(1000);
$min-width: rem(720);


// Color

$black: #000;
$white: #FFF;

  // home-page

    // header

    $nav-bar-color: $black;
    $nav-bar-ff: $font-family-first;
    $nav-bar-fz: rem(18);

    $shopping-basket-ff: $font-family-first;
    $shopping-basket-fz: rem(18);
    $shopping-basket-color: $black;

    // main

      // slider

      $slider-title-ff: $font-family-second;
      $slider-title-fz: rem(36);
      $slider-desc-ff: $font-family-first;
      $slider-desc-fz: rem(18);

      // info

      $link-to-title-ff: $font-family-second;
      $link-to-title-fz: rem(24);
      $link-to-color: $black;
      $link-to-bg-color: #fff;

      $subscription-title-ff: $font-family-second;
      $subscription-title-fz: rem(24);
      $subscription-title-color: #fff;
      $subscription-bg-color: #f37e4a;

        $subscription-input-ff: $font-family-first;
        $subscription-input-fz: rem(16);
        $subscription-input-bg-color: #444444;
        $subscription-input-placeholder-color: #a3a3a3;

        $subscription-btn-ff: $font-family-first;
        $subscription-btn-fz: rem(20);
        $subscription-btn-color: #fff;
        $subscription-btn-bg-color: #000;

      $contacts-title-ff: $font-family-second;
      $contacts-title-fz: rem(24);
      $contacts-data-fz: rem(18);
      $contacts-color: #fff;
      $contacts-bg-color: #000;

//$primary: #000;

@mixin UNSELECTABLE($pref:none) {
  -moz-user-select: $pref;
  -webkit-user-select: $pref;
  -ms-user-select: $pref;
  -o-user-select: $pref;
  user-select: $pref;
}
